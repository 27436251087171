import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Client } from "@twilio/conversations";
import { AttachIcon } from "@twilio-paste/icons/esm/AttachIcon";
import { Box, Button } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Text } from "@twilio-paste/text";

import { AppState, SubscriptionType, actionCreators } from "../../store";
import { MAX_FILE_SIZE, UNEXPECTED_ERROR_MESSAGE } from "../../constants";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import MessageInput from "./MessageInput";
import SendMessageButton from "./SendMessageButton";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { getSdkConversationObject } from "../../conversations-objects";
import { ReduxMessage } from "../../store/reducers/messageListReducer";

import { checkCompanySubscription, isCompanyApproved } from "../../api";

interface SendMessageProps {
  convoSid: string;
  client: Client;
  messages: ReduxMessage[];
  convo: ReduxConversation;
  typingData: string[];
  onSetSendMessageErrorType: (errorType: SendMessageErrors) => void;
}

export enum SendMessageErrors {
  EMPTY_MESSAGE = "EMPTY_MESSAGE",
  COMPANY_LIMITED_ACCESS = "COMPANY_LIMITED_ACCESS",
  COMPANY_NOT_APPROVED = "COMPANY_NOT_APPROVED",
  FREE_SUBSCRIPTION = "FREE_SUBSCRIPTION",
  MESSAGE_TOO_LONG = "MESSAGE_TOO_LONG",
}

const MessageInputField: React.FC<SendMessageProps> = (
  props: SendMessageProps
) => {
  const [message, setMessage] = useState("");
  const messageRef = useRef(message);
  const [files, setFiles] = useState<File[]>([]);
  // needed to clear input type=file
  const [filesInputKey, setFilesInputKey] = useState<string>("input-key");

  const theme = useTheme();
  const typingInfo = getTypingMessage(props.typingData);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { addNotifications, updateUnreadMessages, incrementUnreadCount } =
    bindActionCreators(actionCreators, dispatch);
  const subscriptionInfo = useSelector(
    (state: AppState) => state.subscriptionInformation
  );
  const conversations = useSelector((app: AppState) => app.convos);
  const convoIndex = useSelector(
    (app: AppState) => app.currentConversationIndex
  );
  const maxLength = 3000;
  useEffect(() => {
    setMessage("");
    setFiles([]);
    setFilesInputKey(Date.now().toString());
  }, [props.convo]);

  useEffect(() => {
    if (!files.length) {
      setFilesInputKey(Date.now().toString());
    }
  }, [files]);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files: assets } = event.target;
    if (!assets?.length) {
      return;
    }

    const validFiles = Array.from(assets).filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );

    if (validFiles.length < assets.length) {
      // TODO: show error
    }

    setFiles([...files, ...validFiles]);
  };

  const onFileRemove = (file: string) => {
    const fileIdentityArray = file.split("_");
    const fileIdentity = fileIdentityArray
      .slice(0, fileIdentityArray.length - 1)
      .join();
    const existentFiles = files.filter(
      ({ name, size }) =>
        name !== fileIdentity &&
        size !== Number(fileIdentityArray[fileIdentityArray.length - 1])
    );

    setFiles(existentFiles);
  };

  const onMessageSend = async () => {
    const { client } = props;
    const convo = conversations[convoIndex];
    const sdkConvo = getSdkConversationObject(convo);
    const accountType = localStorage.getItem("type");
    const fromUserId = client.user.identity;
    const toUserId = (await sdkConvo.getParticipants()).find(
      (p) => p.identity !== fromUserId
    )?.identity;

    const convoAttributes = convo.attributes;
    const isSupport = convoAttributes.isSupportChat ?? false;

    setIsLoading(true);
    if (accountType === "worker" && toUserId && !isSupport) {
      const companyApproved = await isCompanyApproved(toUserId);

      if (!companyApproved) {
        props.onSetSendMessageErrorType(
          SendMessageErrors.COMPANY_LIMITED_ACCESS
        );
        setIsLoading(false);
        return;
      }

      try {
        const isCompanySubscriptionFree = await checkCompanySubscription(
          toUserId
        );
        if (isCompanySubscriptionFree) {
          props.onSetSendMessageErrorType(
            SendMessageErrors.COMPANY_LIMITED_ACCESS
          );
          return;
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (accountType === "company") {
      const companyApproved = await isCompanyApproved(
        localStorage.getItem("username") ?? ""
      );
      if (!companyApproved && !isSupport) {
        props.onSetSendMessageErrorType(SendMessageErrors.COMPANY_NOT_APPROVED);
        setIsLoading(false);
        return;
      }

      if (
        subscriptionInfo &&
        subscriptionInfo.type === SubscriptionType.FREE &&
        !isSupport
      ) {
        props.onSetSendMessageErrorType(SendMessageErrors.FREE_SUBSCRIPTION);
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    props.onSetSendMessageErrorType(SendMessageErrors.EMPTY_MESSAGE);
    const newMessageBuilder = sdkConvo
      .prepareMessage()
      .setBody(messageRef.current)
      .setAttributes({ authorName: client.user.friendlyName });

    // const newMessage: ReduxMessage = {
    //   author: client.user.identity,
    //   body: message,
    //   attributes: {},
    //   dateCreated: currentDate,
    //   index: -1,
    //   participantSid: "",
    //   sid: "-1",
    //   aggregatedDeliveryReceipt: null,
    //   attachedMedia: [],
    // } as ReduxMessage;

    for (const [, file] of files.entries()) {
      const fileData = new FormData();
      fileData.set(file.name, file, file.name);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // newMessage.attachedMedia.push({
      //   sid: key + "",
      //   size: file.size,
      //   filename: file.name,
      //   contentType: file.type,
      // });
      // addAttachment(convo.sid, "-1", key + "", file);
      newMessageBuilder.addMedia(fileData);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // addMessages(convo.sid, [newMessage]);
    setMessage("");
    setFiles([]);
    const messageIndex = await newMessageBuilder.build().send();

    try {
      await sdkConvo.updateLastReadMessageIndex(messageIndex);
      updateUnreadMessages(props.convoSid, 0);
    } catch (e) {
      setIsLoading(false);
      unexpectedErrorNotification(addNotifications);
      setIsLoading(false);
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    messageRef.current = message;
  }, [message]);

  useEffect(() => {
    props.onSetSendMessageErrorType(SendMessageErrors.EMPTY_MESSAGE);
  }, [convoIndex]);

  return (
    <Box
      display="flex"
      flexBasis="60px"
      flexGrow={10}
      flexDirection="column"
      borderTopStyle="solid"
      borderTopWidth="borderWidth10"
      style={{
        borderTopColor: theme.borderColors.colorBorderWeak,
        backgroundColor: theme.backgroundColors.colorBackgroundBody,
      }}
    >
      <Box
        paddingBottom="space20"
        paddingTop="space50"
        paddingLeft="space150"
        hidden={!props.typingData.length}
      >
        <Text as="p" color="colorTextIcon">
          {typingInfo}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        flexGrow={10}
        paddingBottom="space30"
        paddingTop="space40"
      >
        <Box
          paddingBottom="space30"
          paddingLeft="space50"
          paddingRight="space10"
          paddingTop="space20"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="start"
        >
          <Button variant="link">
            <label htmlFor="file-input">
              <AttachIcon
                decorative={true}
                title="Attach file"
                size="sizeIcon50"
              />
            </label>
            <input
              id="file-input"
              key={filesInputKey}
              type="file"
              style={{ display: "none" }}
              onChange={onFilesChange}
            />
          </Button>
        </Box>

        <Box paddingRight="space50" flexGrow={10}>
          <MessageInput
            convoSId={props.convoSid}
            onClick={async () => {
              console.log("click");
            }}
            assets={files}
            message={message}
            onChange={(e: string) => {
              if (e.length > maxLength) {
                props.onSetSendMessageErrorType(
                  SendMessageErrors.MESSAGE_TOO_LONG
                );
              } else {
                props.onSetSendMessageErrorType(
                  SendMessageErrors.EMPTY_MESSAGE
                );
              }
              sdkConvo.typing();
              setMessage(e);
            }}
            onKeyPress={async (e) => {
              if (e.key === "Enter") {
                setMessage(messageRef.current + "\n");
              }
            }}
            onFileRemove={onFileRemove}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="start"
        >
          {message || files.length ? (
            <SendMessageButton
              disabled={message.length > maxLength}
              isLoading={isLoading}
              message={messageRef.current}
              onClick={() => {
                onMessageSend();
              }}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default MessageInputField;
