import { Button } from "@twilio-paste/button";
import { Box, Spinner } from "@twilio-paste/core";
import styles from "../../styles";
import { useEffect } from "react";

interface SendMessageButtonProps {
  message: string;
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
}

const SendMessageButton: React.FC<SendMessageButtonProps> = (
  props: SendMessageButtonProps
) => {
  return (
    <Box style={styles.buttonWrapper}>
      <Button
        variant="inverse"
        onClick={() => {
          props.onClick();
        }}
        disabled={props.isLoading || props.disabled}
      >
        {props.isLoading ? (
          <Box>
            <Spinner decorative={false} size="sizeIcon30" title="Loading" />
          </Box>
        ) : (
          "Send"
        )}
      </Button>
    </Box>
  );
};

export default SendMessageButton;
