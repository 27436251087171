import { useDispatch, useSelector } from "react-redux";
import { Action, bindActionCreators } from "redux";

import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import {
  actionCreators,
  AppState,
  SubscriptionInformation,
  SubscriptionType,
} from "../../store";
import ConversationDetails from "./ConversationDetails";
import MessagesBox from "../message/MessagesBox";
import MessageInputField, {
  SendMessageErrors,
} from "../message/MessageInputField";
import styles from "../../styles";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import {
  Dispatch,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { startSupportChat } from "../../api";
import {
  addConversation,
  setCurrentConversationIndex,
} from "../../store/action-creators";

interface ConvoContainerProps {
  conversation?: ReduxConversation;
  client?: Client;
  sid: string;
  isMobile: boolean;
}

async function startSupportChatAndSelect(
  conversations: ReduxConversation[],
  conversationIndexCallback: (idx: number) => void
) {
  const idx = conversations.findIndex((c) => c.attributes.isSupportChat);
  if (idx < 0) {
    await startSupportChat();
  }
  conversationIndexCallback(Math.max(0, idx));
}

const ConversationContainer: React.FC<ConvoContainerProps> = (
  props: ConvoContainerProps
) => {
  const theme = useTheme();
  const [sendMessageErrorType, setSendMessageErrorType] = useState(
    SendMessageErrors.EMPTY_MESSAGE
  );
  const sid = useSelector((state: AppState) => state.sid);
  const loadingStatus = useSelector((state: AppState) => state.loadingStatus);
  const participants = useSelector((state: AppState) => state.participants);
  const messages = useSelector((state: AppState) => state.messages);
  const typingData =
    useSelector((state: AppState) => state.typingData)[sid] ?? [];
  const lastReadIndex = useSelector((state: AppState) => state.lastReadIndex);
  const conversations = useSelector((state: AppState) => state.convos);
  const conversationsRef = useRef(conversations);
  const conversationIndex = useSelector(
    (state: AppState) => state.currentConversationIndex
  );

  const dispatch = useDispatch();
  const { pushMessages, updateCurrentConversation } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const { setCurrentConversationIndex } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [signal, setSignal] = useState(false);
  let mySid = -1;
  useEffect(() => {
    if (signal) {
      mySid = conversations.findIndex((c) => c.sid === sid);
      setCurrentConversationIndex(mySid);
      setSignal(false);
    }
  }, [signal]);

  useEffect(() => {
    conversationsRef.current = conversations;
  }, [conversations]);

  useEffect(() => {
    const f = (e: any) => {
      console.log("Message received from the parent: " + e); // Message received from parent
      if (e?.data === "close_chat") {
        console.log("Removing conversation"); // Message received from parent
        setCurrentConversationIndex(-1);
        updateCurrentConversation("");
      }

      if (e?.data === "add_conversation") {
        console.log("Adding conversation");
        setSignal(true);
      }
    };
    window.addEventListener("message", f);
    return () => {
      window.removeEventListener("message", f, false);
    };
  }, []);

  const displaySendMessageError = () => {
    const sendMessageErrorMessages = {
      [SendMessageErrors.COMPANY_LIMITED_ACCESS]:
        "Failed sending message. This company has limited access to conversations. If you have questions, please chat with inctrl",
      [SendMessageErrors.COMPANY_NOT_APPROVED]:
        "Failed sending message. Conversations are not available until your company is approved. If you have questions, please chat with inctrl",
      [SendMessageErrors.FREE_SUBSCRIPTION]:
        "Failed sending message. Conversations are not available for free subscription. If you have questions, please chat with inctrl",
      [SendMessageErrors.EMPTY_MESSAGE]: "",
      [SendMessageErrors.MESSAGE_TOO_LONG]: "Message exceeds 3000 characters.",
    };

    const errorMessage = sendMessageErrorMessages[sendMessageErrorType];
    const keys = Object.keys(sendMessageErrorMessages);

    return (
      <Box>
        {errorMessage && (
          <>
            <span>{errorMessage}</span>
            {(!keys.includes(SendMessageErrors.EMPTY_MESSAGE) ||
              !keys.includes(SendMessageErrors.MESSAGE_TOO_LONG)) && (
              <span
                onClick={async () => {
                  await startSupportChatAndSelect(
                    conversationsRef.current,
                    setCurrentConversationIndex
                  );
                }}
                style={{
                  marginLeft: "7px",
                  color: "rgb(15, 38, 92)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Support
              </span>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box style={styles.convosWrapperBox}>
      {props.conversation &&
      props.client &&
      conversations &&
      conversationIndex >= 0 ? (
        <>
          <ConversationDetails
            setCurrentConversationIndex={setCurrentConversationIndex}
            convoSid={sid}
            convo={props.conversation}
            participants={
              participants[conversations[conversationIndex].sid] ?? []
            }
            sid={props.sid}
            updateCurrentConversation={updateCurrentConversation}
            isMobile={props.isMobile}
          />
          {!messages[sid]?.length ? (
            <Box
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "Source Sans Pro,sans-serif",
                fontSize: theme.fontSizes.fontSize30,
                fontWeight: theme.fontWeights.fontWeightNormal,
                lineHeight: "20px",
                color: theme.textColors.colorTextIcon,
              }}
            >
              No messages.
            </Box>
          ) : (
            <MessagesBox
              key={sid}
              convoSid={conversations[conversationIndex].sid}
              convo={conversations[conversationIndex]}
              addMessage={pushMessages}
              client={props.client}
              messages={messages[conversations[conversationIndex].sid]}
              loadingState={loadingStatus}
              participants={
                participants[conversations[conversationIndex].sid] ?? []
              }
              lastReadIndex={lastReadIndex}
            />
          )}
          <Box>
            <div
              style={{
                marginLeft: "10px",
                fontSize: "13px",
                color: "#e5005b",
              }}
            >
              {displaySendMessageError()}
            </div>
          </Box>

          <MessageInputField
            convoSid={sid}
            client={props.client}
            messages={messages[sid]}
            convo={props.conversation}
            typingData={typingData}
            onSetSendMessageErrorType={(errorType: SendMessageErrors) => {
              setSendMessageErrorType(errorType);
            }}
          />
        </>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              fontFamily: "Source Sans Pro,sans-serif",
              fontSize: theme.fontSizes.fontSize30,
              fontWeight: theme.fontWeights.fontWeightNormal,
              lineHeight: "20px",
              color: theme.textColors.colorTextIcon,
            }}
          >
            Select a conversation on the left to get started.
          </Box>
        </>
      )}
    </Box>
  );
};

export default ConversationContainer;
